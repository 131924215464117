import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";

const Test = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="Data Processing Addendum" />
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Data Processing Addendum
                  </h2>
                  <p className="gr-text-8 mb-13">
                    How we handle and protect your data
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="d-flex justify-content-center">
          <iframe
            src="/camelo-customer-dpa-2024.pdf"
            style={{ width: "80%", height: "800px" }}
          ></iframe>
        </div>
      </PageWrapper>
    </>
  );
};

export default Test;
